import { put, call } from "redux-saga/effects";
import axios from "axios";
import {
  SHOW_TOAST,
  SET_SPACES_DATA
} from "../actions/types";
import { setFolderDocumentsAction, setAllFolderDocumentsAction } from "../actions/spacesActions";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getSpacesDataSaga(params) {
    yield put({
      type: "SET_SPACES_LOADING",
      payload: true,
    });
    try {
        let getSpacesDataResponse = yield axios.get(
            `${env.newBaseURLDev}/spaces/list_folders/?workspace_id=${JSON.parse(localStorage.getItem("new_workspace")).id}`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
              }
        );
        yield put({
            type: SET_SPACES_DATA,
            payload: getSpacesDataResponse.data,
          });
          yield put({
            type: "SET_SPACES_LOADING",
            payload: false,
          });
    } catch (error) {
        yield put({
            type: SHOW_TOAST,
            payload: true,
          });
    }   
}

export function* getFolderDocumentsSaga(params) {
  yield put({
    type: "SET_FOLDER_LOADING",
    payload: true,
  });
  try {
    const getFolderDocumentsResponse = yield call(axios.get,
      `${env.newBaseURLDev}/spaces/list_documents/?workspace_id=${JSON.parse(localStorage.getItem("new_workspace")).id}&folder_id=${params.params.folder_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    yield put(setFolderDocumentsAction(getFolderDocumentsResponse.data));
    yield put({
      type: "SET_FOLDER_LOADING",
      payload: false,
    });
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "An error occurred while fetching folder documents.",
        toastType: 0,
      },
    });
  }   
}

export function* getAllFolderDocumentsSaga(params) {
  try {
    const getAllFolderDocumentsResponse = yield call(axios.get,
      `${env.newBaseURLDev}/spaces/list_all_workspace_docs/?workspace_id=${JSON.parse(localStorage.getItem("new_workspace")).id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    yield put(setAllFolderDocumentsAction(getAllFolderDocumentsResponse.data));
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "An error occurred while fetching folder documents.",
        toastType: 0,
      },
    });
  }   
}

export function* createSpacesEntitySaga(params) {
  try {
    let requestBody = {
      entity_type: params.params.entity_type,
      name: params.params.name,
      workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id,
    }
    if (params.params.entity_type === "document") {
      requestBody["url"] = params.params.url;
      requestBody["parent_id"] = params.params.parent_id;
      requestBody["icon_url"] = params.params?.icon_url;
      requestBody["cover_url"] = params.params?.cover_url;
    }
    
    const createEntityResponse = yield call(axios.post,
      `${env.newBaseURLDev}/spaces/create_entity/`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    console.log("createEntityResponse",params.params);
    if (createEntityResponse.status === 200 || createEntityResponse.status === 201) {

      if(params.params.onSuccess){
        params.params.onSuccess(createEntityResponse.data);
      }
      if (params.params.entity_type === "document") {
       
        yield put({
          type: SHOW_TOAST,
          data: {
            message: "New Document has been created successfully.",
            toastType: 1,
          },
        });

        yield getFolderDocumentsSaga({params: {folder_id: params.params.parent_id}});
      } else {
        yield put({
          type: SHOW_TOAST,
          data: {
            message: "New Folder has been created successfully.",
            toastType: 1,
          },
        });
        yield getSpacesDataSaga();
      }
    } else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: error?.response?.data?.error || "An error occurred while creating the entity.",
        toastType: 0,
      },
    });
  }
}

export function* renameSpacesEntitySaga(params) {   
  try {
    let renameUrl = `${env.newBaseURLDev}/spaces/rename_folder/`;
    let requestBody = {
      name: params.params?.new_name,
      folder_id: params.params?.folder_id,
    }
    if (params.params.entity_type === "document") {
      renameUrl = `${env.newBaseURLDev}/spaces/rename_document/`;
      requestBody = {
        parent_id: params.params?.parent_id,
        document_id: params.params?.document_id,
        name: params.params?.new_name,
      }
      }
    let renameFolderResponse = yield axios.post(
      renameUrl,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );  
    if (renameFolderResponse.status === 200 || renameFolderResponse.status === 201) {
      if (params.params.entity_type === "document") {
        yield put({
          type: SHOW_TOAST,
          data: {
            message: "Document has been renamed successfully.",
            toastType: 1,
          },
        }); 
        yield getFolderDocumentsSaga({params: {folder_id: params.params.parent_id}});

      }else{
        yield put({
          type: SHOW_TOAST,
          data: {
            message: "Folder has been renamed successfully.",
            toastType: 1,
          },
        });   
        yield getSpacesDataSaga();
      }
        
    }
    else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: error?.response?.data?.error || "An error occurred while renaming the entity.",
        toastType: 0,
      },
    });
  }
}

export function* deleteSpacesEntitySaga(params) {
  try {
    let deleteUrl = `${env.newBaseURLDev}/spaces/delete_folder/`;
    let requestBody = {
      folder_id: params.params?.folder_id,
    }
    if (params.params.entity_type === "document") {
      deleteUrl = `${env.newBaseURLDev}/spaces/delete_document/`;
      requestBody = {
        document_id: params.params?.document_id,
      }
      }
    let deleteFolderResponse = yield axios.post(
      deleteUrl,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );  

    if (deleteFolderResponse.status === 200 || deleteFolderResponse.status === 201  || deleteFolderResponse.status === 204) {
      if (params.params.entity_type === "document") {
        yield put({
          type: SHOW_TOAST,
          data: {
            message: "Document has been deleted successfully.",
            toastType: 1,
          },
        });   
        yield getFolderDocumentsSaga({params: {folder_id: params.params.parent_id}});
      }else{
        yield put({
          type: SHOW_TOAST,
        data: {
          message: "Folder has been deleted successfully.",
          toastType: 1,
        },
      });   
        yield getSpacesDataSaga();  
      }
    }
    else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: error?.response?.data?.error || "An error occurred while deleting the entity.",
        toastType: 0,
      },
    });
  }
}

export function* updateDocumentEntitySaga(params){
  try{
    let updateDocumentImagesResponse = yield axios.post(
      `${env.newBaseURLDev}/spaces/update_document/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );  
    console.log("updateDocumentImagesResponse", updateDocumentImagesResponse);
  } catch (error) {
    console.error("Error in updateDocumentImages:", error);
  }
}
