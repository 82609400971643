export const getUserMetaAction = () => ({
    type: "GET_USER_META",
});

export const setUserMetaAction = data => ({
    type: "SET_USER_META",
    params: data,
});

export const updateUserMetaAction = data => ({
    type: "UPDATE_USER_META",
    params: data,
});