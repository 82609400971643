import { GET_MARKETPLACE_DATA, SET_MARKETPLACE_DATA, SET_MARKETPLACE_DATA_LOADING } from "./types";

export const getMarketplaceDataAction = () => ({
    type: GET_MARKETPLACE_DATA,
});

export const setMarketplaceDataAction = data => ({
    type: SET_MARKETPLACE_DATA,
    params: data,
});

export const setMarketplaceDataLoadingAction = data => ({
    type: SET_MARKETPLACE_DATA_LOADING,
    params: data,
});
