export const white = '#ffffff';
export const lightGrey = '#EBEBEB';
export const grey = '#757575';
export const errorLight = '#E53935';
export const errorLightest = '#FCE7E7';
export const errorDark = '#C62828';
export const black = '#000000';
export const quality = '#2F80ED';
export const hse = '#219653';
export const logistics = '#F2994A';
export const warningDark = '#FFA000';
export const primaryAccent = '#1da3e2';
export const blackInactive = 'rgba(0, 0, 0, 0.54)';
export const primary50 = '#E3F2FD';
export const primary400 = '#1da3e2';
export const successDark = '#00b74c';
export const successLight = '#ECF9F3';
export const grayInactive = '#D7D5D5';
export const primaryActionColor = '#0052CC';
export const primaryActionColorGradiant = 'rgba(24,144,255,0.5)';
export const ctaHover = '#007FF4';
export const ctaDisabled = '#0064DA';
export const activeState = '#0182C8';
export const titleBlack = '#030330';
export const grey50 = 'rgba(3,3,48,0.5)';
export const grey75 = 'rgba(3,3,48,0.75)';
export const grey10 = 'rgba(3,3,48,0.1)';
export const grey20 = 'rgba(3,3,48,0.2)';
export const grey25 = 'rgba(3,3,48,0.25)';
export const grey5 = 'rgba(3,3,48,0.05)';
export const appBackground = '#FFFFFF';
export const backgroundWhite = '#FFFFFF';
export const templateRed = 'red';
export const menuChoice = '#e8fafc';
export const menuChoiceDark = '#0d5760';
