import axios from "axios";
import { put } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import {
  AUTHENTICATION_FAILED,
  HIDE_LOADER,
  // SET_USERS_FOR_SITES,
  SHOW_LOADER,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export function* signUpSaga(params) {
  try {
    let email = params.params.email;
    if (!isValidEmail(email)) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Please enter a valid Email.", toastType: 0 },
      });
    } else if (isValidEmail(email)) {
      let payloadNew = {};
      payloadNew.fullname =
        params.params.firstName + " " + params.params.lastName;
      payloadNew.email = params.params.email;
      payloadNew.password = params.params.password;
      payloadNew.workspace_invite_slug =
        params.params.workspaceInviteSlug ?? "";
      payloadNew.user_invite_code = params.params.userInviteCode ?? "";
      payloadNew.phone_number =
        params.params.phonenumber ??
        Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
          1000000000 +
          " ";
      payloadNew.country_code = params.params.countryCode ?? "IN";
      payloadNew.org_id = params.params.orgId ?? "";
      payloadNew.sub_id = params.params.sub_id ?? "";

      yield put({ type: SHOW_LOADER });
      const newSignUpResponse = yield axios.post(
        `${env.newBaseURLDev}/users/user/auth_zero_signup/`,
        payloadNew,
        {
          headers: {
            Authorization: params.params.accessToken,
          },
        }
      );

      if (newSignUpResponse.status === 201) {
        yield put({ type: HIDE_LOADER });
        // console.log(decoded);
        localStorage.setItem(
          "new_resource_owner",
          newSignUpResponse?.data?.user
        );
        localStorage.setItem("token", params.params.accessToken);
        localStorage.setItem("drawerOpen", true);
        localStorage.setItem(
          "new_org_details",
          JSON.stringify(newSignUpResponse?.data?.org_details)
        );
        localStorage.setItem(
          "new_workspace",
          JSON.stringify(newSignUpResponse?.data?.workspace)
        );
        localStorage.setItem(
          "new_board",
          JSON.stringify(newSignUpResponse?.data?.board)
        );

        if (!params.params.redirect) {
          window.location = `/home`;
        } else {
          // console.log(params.params.redirect);
          window.location = params.params.redirect;
        }
        // if (decoded.phone_verified === 0) {
        //     window.location = '/verify-phone';
        // }
        // else {
        //     window.location = '/home';
        // }
      }
    }
  } catch (e) {
    console.log(e);
    yield put({ type: HIDE_LOADER });
    yield put({ type: AUTHENTICATION_FAILED });
    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Account already exists, please Log In.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* logInSaga(params) {
  try {
    if (!isValidEmail(params.params.email)) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Please enter a valid Email.", toastType: 0 },
      });
      yield put({ type: AUTHENTICATION_FAILED });
    } else {
      let payload = {};
      payload.email = params.params.email;
      payload.password = params.params.password;

      yield put({ type: SHOW_LOADER });
      const newSignInResponse = yield axios.post(
        `${env.newBaseURLDev}/users/user/login/`,
        payload
      );

      if (newSignInResponse.status === 200) {
        yield put({ type: HIDE_LOADER });
        const decoded = jwt_decode(newSignInResponse?.data?.token);
        // console.log(decoded);
        localStorage.setItem(
          "new_resource_owner",
          JSON.stringify(newSignInResponse?.data?.user)
        );
        localStorage.setItem(
          "new_org_details",
          JSON.stringify(newSignInResponse?.data?.org_details)
        );
        localStorage.setItem(
          "new_workspace",
          JSON.stringify(newSignInResponse?.data?.workspace)
        );
        localStorage.setItem(
          "new_board",
          JSON.stringify(newSignInResponse?.data?.board)
        );
        localStorage.setItem("token", newSignInResponse?.data?.token);

        localStorage.setItem("drawerOpen", true);
        if (decoded?.email_verified === 0) {
          window.location = "/verify-email";
        }

        if (!params.params.redirect) {
          window.location = "/home";
        } else {
          // console.log(params.params.redirect);
          window.location = params.params.redirect;
        }
      }
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    yield put({ type: AUTHENTICATION_FAILED });
    if (e.message.includes("404")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Account does not exist. Please create one.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("401")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Incorrect Email or Password.", toastType: 0 },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* forgotPasswordSaga(params) {
  try {
    if (!isValidEmail(params.params)) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Please enter a valid Email.", toastType: 0 },
      });
    } else {
      yield put({ type: SHOW_LOADER });
      // let forgotPasswordResponse = yield axios.post(
      //   `${env.baseURLDev}/user/send-reset-link`,
      //   { email: params.params }
      // );
      // if (forgotPasswordResponse.status === 200) {
      //   yield put({ type: HIDE_LOADER });
      //   yield put({
      //     type: SHOW_TOAST,
      //     data: {
      //       message:
      //         "If an account exists with submitted email, you will receive a password reset link in your inbox.",
      //       toastType: 1,
      //     },
      //   });
      // }
      // yield put({ type: SHOW_TOAST, data: { message: "Valid Email!", toastType: 1 } });
    }
  } catch {
    yield put({ type: HIDE_LOADER });
  }
}

export function* resetPasswordSaga(params) {
  try {
    yield put({ type: SHOW_LOADER });
    // let resetPasswordResponse = yield axios.post(
    //   `${env.baseURLDev}/user/reset-password`,
    //   params.params
    // );
    // if (resetPasswordResponse.status === 200) {
    //   yield put({ type: HIDE_LOADER });
    //   yield put({
    //     type: SHOW_TOAST,
    //     data: {
    //       message: "Password reset successfully, you can log in.",
    //       toastType: 1,
    //     },
    //   });
    // }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Password should be at least 6 characters.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("401")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Passwords do not match.", toastType: 0 },
      });
    }

    if (e.message.includes("404")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Oops! Token invalid or expired.", toastType: 0 },
      });
    }
  }
}

export function* getUsersForSites(params) {
  try {
    yield put({ type: SHOW_LOADER });
    // let getUsersForSitesResponse = yield axios.get(
    //   `${env.baseURLDev}/site/get-users-for-sites`,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );
    // if (getUsersForSitesResponse.status === 200) {
    //   // yield put({ type: HIDE_LOADER });
    //   yield put({
    //     type: SET_USERS_FOR_SITES,
    //     data: params?.params?.slug
    //       ? getUsersForSitesResponse.data.data.filter(
    //           (item) => item.site_slug === params.params.slug
    //         )
    //       : getUsersForSitesResponse.data.data,
    //   });
    // }
  } catch {
    // yield put({ type: HIDE_LOADER });
  }
}

export function* updateAvatarSaga(params) {
  try {
    // let url = params.params;
    // let updateAvatarSaga = yield axios.post(
    //   `${env.baseURLDev}/user/update-avatar`,
    //   { url },
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );
    // if (updateAvatarSaga.status === 200) {
    //   const decoded = jwt_decode(updateAvatarSaga?.data?.token);
    //   localStorage.setItem("resource_owner", JSON.stringify(decoded));
    //   localStorage.setItem("token", updateAvatarSaga?.data?.token);
    //   yield put({
    //     type: SHOW_TOAST,
    //     data: { message: "Avatar updated.", toastType: 1 },
    //   });
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again.",
        toastType: 0,
      },
    });
  }
}

export function* updateProfileDetailsSaga(params) {
  try {
    // let { firstName, lastName, email } = params.params;
    console.log(email);
    // let updateProfileDetailsResponse = yield axios.post(
    //   `${env.baseURLDev}/user/update-profile-details`,
    //   { first_name: firstName, last_name: lastName, email },
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (updateProfileDetailsResponse.status === 200) {
    //   const decoded = jwt_decode(updateProfileDetailsResponse?.data?.token);
    //   localStorage.setItem("resource_owner", JSON.stringify(decoded));
    //   localStorage.setItem("token", updateProfileDetailsResponse?.data?.token);
    //   if (decoded?.email_verified === 0) {
    //     setTimeout(() => (window.location = "/verify-email"), 1000);
    //   }
    //   yield put({
    //     type: SHOW_TOAST,
    //     data: { message: "Personal information updated.", toastType: 1 },
    //   });
    // }
  } catch (e) {
    console.log(e);
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again.",
        toastType: 0,
      },
    });
  }
}

export function* updatePasswordSaga(params) {
  try {
    // let { current_password, new_password, confirm_new_password } =
    //   params.params;

    if (new_password.length < 6) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Password too short (should be at least 6 characters).",
          toastType: 0,
        },
      });
    } else {
      // let resetPasswordResponse = yield axios.post(
      //   `${env.baseURLDev}/user/change-password`,
      //   { current_password, new_password, confirm_new_password },
      //   {
      //     headers: {
      //       authorization: `${localStorage.getItem("token")}`,
      //     },
      //   }
      // );
      // if (resetPasswordResponse.status === 200) {
      //   yield put({
      //     type: SHOW_TOAST,
      //     data: {
      //       message:
      //         "Password updated successfully. You can use it the next time you Log In.",
      //       toastType: 1,
      //     },
      //   });
      // }
    }
  } catch (e) {
    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Current and new passwords cannot be the same.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("401")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Passwords do not match.", toastType: 0 },
      });
    }

    if (e.message.includes("402")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Incorrect password.", toastType: 0 },
      });
    }
  }
}
