import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SHOW_TOAST,
  SET_MARKETPLACE_DATA,
  SET_MARKETPLACE_DATA_LOADING
} from "../actions/types";


const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getMarketplaceDataSaga(params) {
    try {
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: true,
          });
        let getMarketplaceDataResponse = yield axios.get(
            `${env.newBaseURLDev}/templates/template/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
              }
        );
        yield put({
            type: SET_MARKETPLACE_DATA,
            payload: getMarketplaceDataResponse.data,
          });
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: false,
          });
    } catch (error) {
        yield put({
            type: SHOW_TOAST,
            payload: true,
          });
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: false,
          });
    }   
}
