import {
  CREATE_STRIPE_SESSION,
  GET_CURRENT_PLAN,
  GET_STRIPE_PLANS,
  GET_STRIPE_TRANSACTION_STATUS,
} from "../actions/types";

export const getCurrentPlanAction = (data) => ({
  type: GET_CURRENT_PLAN,
  params: data,
});

export const getStripePlansAction = (data) => ({
  type: GET_STRIPE_PLANS,
  params: data,
});

export const createStripeSessionAction = (data) => ({
  type: CREATE_STRIPE_SESSION,
  params: data,
});

export const checkStripeTransactionAction = (data) => ({
  type: GET_STRIPE_TRANSACTION_STATUS,
  params: data,
});
