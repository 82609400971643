import {
  CREATE_STRIPE_SESSION,
  GET_CURRENT_PLAN,
  GET_STRIPE_PLANS,
  GET_STRIPE_TRANSACTION_STATUS,
  SET_CURRENT_PLAN,
  SET_STRIPE_PLANS,
  SET_STRIPE_SESSION,
  SET_STRIPE_TRANSACTION_STATUS,
} from "../actions/types";

const initialState = {
  sessionId: null,
  transactionStatus: null,
  sessionIdLoading: false,
  transactionStatusLoading: false,
  plans: [],
  plansLoading: false,
  currentPlan: null,
  currentPlanLoading: false,
};

export default function planReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_PLAN:
      return { ...state, currentPlanLoading: true };

    case SET_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: action.payload,
        currentPlanLoading: false,
      };

    case GET_STRIPE_PLANS:
      return { ...state, plansLoading: true };

    case SET_STRIPE_PLANS:
      return { ...state, plans: action.payload, plansLoading: false };

    case CREATE_STRIPE_SESSION:
      return { ...state, sessionIdLoading: true };

    case GET_STRIPE_TRANSACTION_STATUS:
      return { ...state, transactionStatusLoading: true };

    case SET_STRIPE_SESSION:
      return {
        ...state,
        sessionIdLoading: false,
        sessionId: action.payload,
      };

    case SET_STRIPE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatusLoading: false,
        transactionStatus: action.payload,
      };

    default:
      return state;
  }
}
