import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_SEARCH_RESULTS,
  SEARCH_LOADED,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getSearchResultsSaga(params) {
  try {
    let getSearchResultsResponse = yield axios.post(
      `${env.newBaseURLDev}/search/search_entities/?query=${params.payload.query}`,
      {
        filters: {
          entity_type: ["ACTION"],
          file_type: [],
          date: params.payload.filters.date,
          event_by: params.payload.filters.event_by,
        },
        params: {
          workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id,
          org_id: JSON.parse(localStorage.getItem("new_org_details")).id,
          board_id: JSON.parse(localStorage.getItem("new_board")).id,
        },
      }
    );

    if (getSearchResultsResponse.status === 200) {
      yield put({
        type: SET_SEARCH_RESULTS,
        payload: getSearchResultsResponse.data,
      });
      yield put({
        type: SEARCH_LOADED,
        payload: true,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    yield put({
      type: SEARCH_LOADED,
      payload: true,
    });
  }
}
