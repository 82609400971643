import {
  ADD_NEW_SITE,
  GET_ALL_SITES,
  GET_ACCESS_SITES,
  ADD_NEW_BOARD,
  ADD_USER_TO_PROJECT,
  GET_USERS_FOR_SEARCH,
  LEAVE_SITE,
  UPDATE_SITE_AVATAR,
  UPDATE_SITE_DETAILS,
  UPDATE_MAN_HOURS,
  GET_WORKSPACE_MAN_HOURS,
  SAVE_MAN_HOURS,
  GET_DASHBOARD_COUNT,
  GET_COMBINED_SITES,
  RESET_ALL_SITES,
  SET_SELECTED_WORKSPACE,
  GET_SELECTED_WORKSPACE,
} from "./types";

export const addNewSiteAction = (data) => ({
  type: ADD_NEW_SITE,
  params: data,
});

export const getAllSitesAction = () => ({
  type: GET_ALL_SITES,
});

export const getCombinedSitesAction = () => ({
  type: GET_COMBINED_SITES,
});

export const getAccessSitesAction = () => ({
  type: GET_ACCESS_SITES,
});

export const addNewBoardAction = (data) => ({
  type: ADD_NEW_BOARD,
  params: data,
});

export const addUserToProjectAction = (data) => ({
  type: ADD_USER_TO_PROJECT,
  params: data,
});

export const getUsersForSearchAction = () => ({
  type: GET_USERS_FOR_SEARCH,
});

export const leaveSiteAction = (data) => ({
  type: LEAVE_SITE,
  params: data,
});

export const updateSiteAvatarAction = (data) => ({
  type: UPDATE_SITE_AVATAR,
  params: data,
});

export const updateSiteDetailsAction = (data) => ({
  type: UPDATE_SITE_DETAILS,
  params: data,
});

export const updateManHoursAction = (data) => {
  return {
    type: UPDATE_MAN_HOURS,
    params: data,
  };
};

export const getWorkspaceManHoursAction = (data) => ({
  type: GET_WORKSPACE_MAN_HOURS,
  params: data,
});

export const saveManHoursAction = (data) => ({
  type: SAVE_MAN_HOURS,
  params: data,
});

export const getDashboardCountAction = (data) => ({
  type: GET_DASHBOARD_COUNT,
  params: data,
});

export const resetAllSitesAction = () => ({
  type: RESET_ALL_SITES,
});

export const getSelectedWorkspace = () => ({
  type: GET_SELECTED_WORKSPACE,
});

export const setSelectedWorkspace = (data) => ({
  type: SET_SELECTED_WORKSPACE,
  params: data,
});
