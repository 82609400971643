import { StyleSheet } from 'aphrodite';
import { backgroundWhite } from '../../colors';

export const styles = StyleSheet.create({
    mobileNavigationOuter: {
        position: 'fixed',
        zIndex: 100,
        backgroundColor: backgroundWhite,
        width: "100vw",
        height: `"calc(100vh - 72px)"`,
        bottom: 0, 
        boxShadow: '0 -2px 1px 0 #ccc',
        padding: '0 .5em .5em .5em',
    }
});