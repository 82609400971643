import {
  SET_DRAWER,
  SET_ACTIVE_TAB,
  OPEN_ACTIVITY_DRAWER,
  CLOSE_ACTIVITY_DRAWER,
  CLOSE_CONTEXT_MENU_DRAWER,
  OPEN_CONTEXT_MENU_DRAWER,
} from "./types";

export const setOpenAction = () => ({
  type: SET_DRAWER,
});

export const setActiveTabAction = (data) => ({
  type: SET_ACTIVE_TAB,
  params: data,
});

export const openActivityDrawerAction = () => ({
  type: OPEN_ACTIVITY_DRAWER,
});

export const closeActivityDrawerAction = () => ({
  type: CLOSE_ACTIVITY_DRAWER,
});

export const openContextMenuDrawerAction = (data) => ({
  type: OPEN_CONTEXT_MENU_DRAWER,
  params: data,
});

export const closeContextMenuDrawerAction = () => ({
  type: CLOSE_CONTEXT_MENU_DRAWER,
});
