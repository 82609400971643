import React, { useEffect, useState } from "react";
import { useNavigate, Link as Rlink } from "react-router-dom";

import { css } from "aphrodite";
import { styles } from "./mobileNavigationStyles";
import dashboardSample from "../../assets/dashboardSample.svg";
import tasks from "../../assets/tasks.svg";
import home from "../../assets/home.svg";
import globalSettings from "../../assets/globalSettings.svg";
import notificationBellMobile from "../../assets/notificationBellMobile.svg";
import { connect } from "react-redux";
import { getAllNotificationsAction } from "../../actions/notificationsActions";
import { openContextMenuDrawerAction } from "../../actions/drawerActions";
import { BottomNav } from "@presto-labs-ai/design-system";

const MobileNavigation = ({
  getAllNotifications,
  openContextMenuDrawer,
  paginatedNotifications,
  allSites,
  allAccessSites,
}) => {
  const navigate = useNavigate();

  const { pathname } = window.location;

  const searchParams = new URLSearchParams(document.location.search);
  const [combinedSites, setCombinedSites] = useState([]);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  useEffect(() => {
    getAllNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allSites?.length > 0 && allAccessSites?.length > 0) {
      setCombinedSites([...allSites, ...allAccessSites]);
    }

    if (allSites?.length > 0 && allAccessSites?.length < 1) {
      setCombinedSites([...allSites]);
    }

    if (allSites?.length < 1 && allAccessSites?.length > 0) {
      setCombinedSites([...allAccessSites]);
    }
  }, [allSites, allAccessSites]);

  const checkLocationAndRoute = (index) => {
    if (
      pathname.includes("dashboards") ||
      pathname.includes("dashboard") ||
      pathname.includes("sites") ||
      pathname.includes("page-not-found")
    ) {
      if (index === 0) {
        return navigate(`/home`);
      }

      if (index === 1) {
        return navigate(`/tasks`);
      }

      if (index === 3) {
        return navigate(`/workspace-settings`);
      }
    } else {
      if (index === 0) {
        return navigate(`/home?site=${searchParams.get("site")}`);
      }

      if (index === 1) {
        return navigate(
          `/tasks?site=${searchParams.get("site")}&board=${
            combinedSites.filter(
              (site) => site.slug === searchParams.get("site")
            )[0]?.board_slug
          }`
        );
      }

      if (index === 2) {
        return navigate("/dashboards");
      }

      if (index === 3) {
        return navigate(
          `/workspace-settings?site=${searchParams.get("site")}&tab=details`
        );
      }
    }

    if (index === 4) {
      return () => {
        openContextMenuDrawer({ option: 8, paginatedNotifications });
        setNotificationsOpen(!notificationsOpen);
      };
    }

    return "";
  };

  const navOptions = [
    {
      label: "Home",
      icon: home,
      iconSelected: home,
      onClick: () => checkLocationAndRoute(0),
      href: "/home",
    },
    {
      label: "Issues",
      icon: tasks,
      iconSelected: tasks,
      onClick: () => checkLocationAndRoute(1),
      href: "/tasks",
    },
    {
      label: "Dashboards",
      icon: dashboardSample,
      iconSelected: dashboardSample,
      onClick: () => checkLocationAndRoute(2),
      href: "/dashboards",
    },
    {
      label: "Settings",
      icon: globalSettings,
      iconSelected: globalSettings,
      onClick: () => checkLocationAndRoute(3),
      href: "/workspace-settings",
    },
    {
      label: "Notifications",
      icon: notificationBellMobile,
      iconSelected: notificationBellMobile,
      onClick: checkLocationAndRoute(4),
    },
  ];

  return (
    <div className={css(styles.mobileNavigationOuter)}>
      <BottomNav
        activePath={pathname}
        navItems={navOptions}
        reactRouterLink={Rlink}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  allSites: state.site.allSites,
  allAccessSites: state.site.allAccessSites,
  paginatedNotifications: state.notifications.paginatedNotifications,
});

const mapDispatchToProps = {
  getAllNotifications: getAllNotificationsAction,
  openContextMenuDrawer: openContextMenuDrawerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
